














import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class LoadingSpinner extends Vue {
  @Prop({ default: '#000' }) color!: string
  @Prop({ default: true }) isFullScreen!: boolean
  @Prop({ default: false }) isSmall!: boolean

  get classNames() {
    return [
      { 'loading-spinner--fullscreen': this.isFullScreen },
      { 'loading-spinner--small': this.isSmall },
    ];
  }

  get style() {
    return {
      'border-color': `${this.color} transparent transparent transparent`,
    };
  }
}
