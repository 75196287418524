











































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import Headline from './Headline.vue';
import ArtistTile from './ArtistTile.vue';
import { Artist } from '../types';

@Component({
  components: {
    Headline,
    ArtistTile,
  },
})

export default class ArtistOverlay extends Vue {
  @Prop() isOpen!: boolean

  @Getter userArtists!: Artist[]
  @Mutation('SET_USER_ARTISTS') setArtists!: (artist: Artist[]) => void

  @Watch('isOpen')
  onToggleOpen() {
    if (this.isOpen) {
      document.body.classList.add('scroll-block');
    } else {
      document.body.classList.remove('scroll-block');
    }
  }

  onArtistDelete(artistId: string) {
    this.setArtists([
      ...this.userArtists.filter((a) => a.spotify_id !== artistId),
    ]);
  }
}
