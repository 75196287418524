export const LOCALES = ['en', 'de'];

export const MESSAGES = {
  en: {
    locales: {
      en: 'EN',
      de: 'DE',
    },
    userError: {
      title: 'Oeps!',
      text: 'Incorrect parameters',
    },
    header: {
      title: 'My Preferences',
    },
    intro: {
      title: 'Hi {name}',
      text: 'Here you can manage everything related to your music interests and subscriptions, save them and customize them according to your preferences. So we can give you even better recommendations and music you\'ll love.',
    },
    genres: {
      title: 'Your Top Genres',
      subtitle: 'Top Genres',
      subtitleSingle: 'Top Genre',
      subtitleAll: 'All',
      country: 'Country',
      dance_edm: 'Dance/EDM',
      deutschrap: 'Deutschrap',
      electronic: 'Electronic/Progressive',
      hip_hop: 'Hiphop',
      audio_book: 'Audio books and plays',
      indie_alternative: 'Indie/Alternative',
      jazz: 'Jazz',
      klassik: 'Classical',
      latin: 'Latin',
      metal: 'Metal',
      music_for_kids: 'Music for Kids',
      pop: 'Pop',
      rnb: 'RnB',
      rock: 'Rock',
      schlager: 'Schlager',
    },
    connect: {
      title1: 'Connect your Spotify',
      text1: 'If you like, you can connect your Spotify account and share your top artists and favorite genres with us. This way we can give you even better recommendations. You can disconnect at any time.',
      title2: 'Even easier: Connect your Spotify',
      text2: 'Instead of searching for your top artists, you can also connect your Spotify account and share them that way. You can disconnect at any time.',
      connect: 'Connect Spotify',
    },
    connected: {
      title: 'Connect as {name}',
      text: 'You are connected to your Spotify account.',
      refresh: 'Refresh',
      disconnect: 'Disconnect',
    },
    loginModal: {
      title: 'Connect to Spotify',
      text: 'More Information on Social Connect and the use of your data at <a href="https://www.universal-music.de/rechtliche-hinweise/security-and-privacy" target="_blank">Security & Privacy</a>.',
      button: 'Connect',
      buttonCancel: 'Cancel',
    },
    form: {
      title: 'Your Profile',
      email: 'E-Mail',
      firstName: 'First Name',
      lastName: 'Last name',
      addressLine1: 'Address line 1',
      addressLine2: 'Address line 2',
      city: 'City',
      postcode: 'Postcode',
      country: 'Country',
      phone: 'Phone',
      birthDay: 'Day of Birth',
      birthMonth: 'Month Of Birth',
    },
    artists: {
      title: 'Your Top Artists',
      amount: 'Artists selected',
      amountSingle: 'Artist selected',
      all: 'See All',
      inputPlaceholder: 'Search for artists to add',
    },
    subscription: {
      title: 'Your E-mail Subscriptions',
      placeholderEmail: 'E-MAIL',
      placeholderFirstName: 'First Name',
      placeholderCountry: 'Country',
      subscribeText1: 'With your registration you confirm that you want to receive our German {book} newsletters.',
      subscribeButton: 'Subscribe',
      subscribeText2: 'Receive the latest information about releases, giveaways and contests by e-mail. You may withdraw your consent at any time. More information at <a href="https://www.universal-music.de/rechtliche-hinweise/security-and-privacy" target="_blank">Security and Privacy</a>.',
      unsubscribeTitle: 'Are you sure? We\'re sorry to see you go',
      unsubscribeText1: 'You can unsubscribe from our newsletter by clicking on the "unsubscribe" button.',
      unsubscribeButton: 'Unsubscribe',
      onSendTitle: 'Thank you. Just one more step',
      onSendText: 'Your data security is important to us: we have sent you an e-mail to confirm your registration',
    },
    sticky: {
      title: '{count} Changes made',
      cancel: 'Cancel',
      save: 'Save',
    },
    footer: {
      title: 'If you would like more information about the data we hold or wish to unsubscribe from all UMG messaging please <a href="https://umgprivacy.zendesk.com/hc/en-us" target="_blank">click here</a>.',
      imprintLink: 'https://www.universal-music.de/rechtliche-hinweise/imprint',
      imprintTitle: 'Imprint',
      privacyLink: 'https://www.universal-music.de/rechtliche-hinweise/security-and-privacy',
      privacyTitle: 'Security & Privacy',
    },
  },
  de: {
    locales: {
      en: 'EN',
      de: 'DE',
    },
    userError: {
      title: 'Oops!',
      text: 'Ungültige Eingaben',
    },
    header: {
      title: 'Meine Einstellungen',
    },
    intro: {
      title: 'Hi {name}',
      text: 'Hier kannst du alles rund um deine Musikinteressen und Abonnements verwalten, speichern und immer wieder nach deinen Wünschen anpassen. Damit wir dir noch bessere Empfehlungen geben können, auf dich zugeschnitten und mit Musik, die du lieben wirst.',
    },
    genres: {
      title: 'Deine Top Genres',
      subtitle: 'Top Genres',
      subtitleSingle: 'Top Genre',
      subtitleAll: 'Alle',
      country: 'Country',
      dance_edm: 'Dance/EDM',
      deutschrap: 'Deutschrap',
      electronic: 'Electronic/Progressive',
      hip_hop: 'Hiphop',
      audio_book: 'Hörbücher und -spiele',
      indie_alternative: 'Indie/Alternative',
      jazz: 'Jazz',
      klassik: 'Klassik',
      latin: 'Latin',
      metal: 'Metal',
      music_for_kids: 'Musik für Kids',
      pop: 'Pop',
      rnb: 'RnB',
      rock: 'Rock',
      schlager: 'Schlager',
    },
    connect: {
      title1: 'Verbinde dein Spotify',
      text1: 'Wenn du möchtest, kannst du deinen Spotify-Account verbinden und so deine Top Artists und Genres mit uns teilen. So bekommst du künftig auf dich zugeschnittene Empfehlungen und Musik, die du lieben wirst. Du kannst diese Verbindung jederzeit trennen.',
      title2: 'Noch einfacher: Verbinde dein Spotify',
      text2: 'Statt nach deinen Top Artists zu suchen, kannst du auch deinen Spotify Account verbinden und sie auf diese Weise mit uns teilen. Du kannst die Verbindung jederzeit trennen.',
      connect: 'Spotify verbinden',
    },
    connected: {
      title: 'Verbunden mit {name}',
      text: 'Du bist mit deinem Spotify Account verbunden.',
      refresh: 'Aktualisieren',
      disconnect: 'Verbindung trennen',
    },
    loginModal: {
      title: 'Mit Spotify verbinden',
      text: 'Mehr Informationen zu Social Connect und dem Umgang mit Deinen Daten unter <a href="https://www.universal-music.de/rechtliche-hinweise/sicherheit-und-datenschutz" target="_blank">Sicherheit & Datenschutz</a>.',
      button: 'Verbinden',
      buttonCancel: 'Abbrechen',
    },
    form: {
      title: 'Dein Profil',
      email: 'E-Mail',
      firstName: 'Vorname',
      lastName: 'Nachname',
      addressLine1: 'Adresse 1',
      addressLine2: 'Adresse 2',
      city: 'Stadt',
      postcode: 'Postleitzahl',
      country: 'Land',
      phone: 'Telefonnummer',
      birthDay: 'Geburtstag',
      birthMonth: 'Geburtsmonat',
    },
    artists: {
      title: 'Deine Top Artists',
      amount: 'Artists ausgewählt',
      amountSingle: 'Artist ausgewählt',
      all: 'Alle ansehen',
      inputPlaceholder: 'Suche Artists',
    },
    subscription: {
      title: 'Deine E-Mail Abonnements',
      placeholderEmail: 'E-Mail',
      placeholderFirstName: 'Vorname',
      placeholderCountry: 'Land',
      subscribeText1: 'Mit Deiner Anmeldung bestätigst Du, dass Du unsere {book} Newsletter erhalten möchtest.',
      subscribeButton: 'abonnieren',
      subscribeText2: 'Erhalte per E-Mail immer die neuesten Informationen zu Releases, Gewinnspielen und Aktionen. Du kannst Deine Einwilligung jederzeit widerrufen. Mehr Informationen unter <a href="https://www.universal-music.de/rechtliche-hinweise/sicherheit-und-datenschutz" target="_blank">Sicherheit und Datenschutz.</a>',
      unsubscribeTitle: 'Bist du sicher? Wir würden uns freuen weiter in Kontakt zu bleiben',
      unsubscribeText1: 'Mit Klick auf den Button "Abmelden" meldest du dich von unserem Newsletter ab',
      unsubscribeButton: 'Abmelden',
      onSendTitle: 'Vielen Dank. Nur noch ein Schritt',
      onSendText: 'Deine Sicherheit ist uns wichtig: Wir haben dir eine E-mail gesandt, mit der du deine Anmeldung bestätigst.',
    },
    sticky: {
      title: '{count} Änderungen',
      cancel: 'Abbrechen',
      save: 'Speichern',
    },
    footer: {
      title: 'Wenn Du weitere Informationen zu Deinen von uns gespeicherten Daten wünschst oder alle UMG-Newsletter abbestellen möchten, <a href="https://umgprivacy.zendesk.com/hc/en-us" target="_blank">klicke hier</a>.',
      imprintLink: 'https://www.universal-music.de/rechtliche-hinweise/impressum',
      imprintTitle: 'Impressum',
      privacyLink: 'https://www.universal-music.de/rechtliche-hinweise/sicherheit-und-datenschutz',
      privacyTitle: 'Sicherheit & Datenschutz',
    },
  },
};
