







import { Component, Prop, Vue } from 'vue-property-decorator';
import Headline from './Headline.vue';

@Component({
  components: {
    Headline,
  },
})

export default class HeadlineTextBlock extends Vue {
  @Prop() headline!: string

  @Prop() text!: string
}
