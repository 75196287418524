import Vue from 'vue';
import VueI18n from 'vue-i18n';
import App from './App.vue';
import store from './store';
import { LOCALES, MESSAGES } from './locale';

Vue.use(VueI18n);
Vue.config.productionTip = false;

const i18n = new VueI18n({
  locale: LOCALES[0],
  messages: MESSAGES,
});

new Vue({
  i18n,
  store: (store as any),
  render: (h) => h(App),
}).$mount('#app');
