














































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import SwitchComp from './SwitchComp.vue';
import InputText from './InputText.vue';
import Button from './Button.vue';
import { AddressBook } from '../types';

@Component({
  components: {
    SwitchComp,
    InputText,
    Button,
  },
})
export default class SubscriptionBox extends Vue {
  @Prop() addressBook!: AddressBook;
  @Prop() isSwitchedOn!: boolean;

  @Getter userEmail!: string;
  @Getter userFirstName!: string;
  @Getter userCountry!: string;

  isEditOpen = false;
  isSend = false;

  toggleIsOpen() {
    this.isEditOpen = !this.isEditOpen;
  }

  get isSubscribeOpen(): boolean {
    return this.isEditOpen === true && this.isSwitchedOn === false;
  }

  get isUnsubscribeOpen(): boolean {
    return this.isEditOpen === true && this.isSwitchedOn === true;
  }

  onSubscribe() {
    this.$emit('onSubscribe');
    this.isSend = true;
    this.isEditOpen = false;
  }

  onUnsubscribe() {
    this.$emit('onUnsubscribe');
    this.isEditOpen = false;
  }
}
