


















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class InputSelect extends Vue {
  @Prop() label!: string
  @Prop() value!: string
  @Prop() options!: { id: string; label: string }[]
}
