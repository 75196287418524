



















import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class';
import { AddressBook } from '../types';
import Headline from './Headline.vue';
import SubscriptionBox from './SubscriptionBox.vue';

@Component({
  components: {
    Headline,
    SubscriptionBox,
  },
})
export default class SubscriptionsBlock extends Vue {
  @Getter userAddressBooks!: AddressBook[]
  @Getter addressBooks!: AddressBook[]

  @Action('LOAD_ADDRESS_BOOKS') load!: () => Promise<void>
  @Action('UPDATE_USER_ADDRESS_BOOKS') updateUser!: () => Promise<void>

  @Mutation('SET_USER_ADDRESS_BOOKS') setAddressBooks!: (data: AddressBook[]) => Promise<void>

  getIsSwitchedOn(addressBookId: string): boolean {
    return !!this.userAddressBooks.find((a) => a.id === addressBookId);
  }

  async onSubscribe(addressBook: AddressBook) {
    await this.setAddressBooks([
      ...this.userAddressBooks,
      addressBook,
    ]);
    await this.updateUser();
  }

  async onUnsubscribe(addressBook: AddressBook) {
    await this.setAddressBooks([
      ...this.userAddressBooks.filter((ab) => ab.id !== addressBook.id),
    ]);
    await this.updateUser();
  }

  async created() {
    await this.load();
  }
}
