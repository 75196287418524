



























import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import Headline from './Headline.vue';
import GenreTile from './GenreTile.vue';
import { GENRES } from '../genres';

@Component({
  components: {
    Headline,
    GenreTile,
  },
})

export default class GenreBlock extends Vue {
  @Getter isUserLoaded!: boolean

  get genres() {
    return GENRES.map((g) => ({
      ...g,
      isActive: this.userGenres.includes(g.id),
    }));
  }

  get userGenres() {
    return this.$store.getters.userGenres;
  }
  set userGenres(val: string[]) {
    this.$store.commit('SET_USER_GENRES', val);
  }

  toggleGenre(genreId: string) {
    if (this.userGenres.includes(genreId)) {
      this.userGenres = this.userGenres.filter((g) => g !== genreId);

      return;
    }

    this.userGenres = [...this.userGenres, genreId];
  }
}
