






























import { Component, Vue } from 'vue-property-decorator';
import SwitchComp from './SwitchComp.vue';
import { LOCALES } from '../locale';

@Component({
  components: {
    SwitchComp,
  },
})
export default class Button extends Vue {
  get languages(): { id: string; label: string }[] {
    return LOCALES.map((id) => ({ id, label: this.$t(`locales.${id}`).toString() }));
  }

  onLanguage(id: string) {
    this.$i18n.locale = id;
  }
}
