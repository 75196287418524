























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Headline extends Vue {
  @Prop() image!: string
  @Prop() isPlus!: boolean
  @Prop() isCloseOpen!: boolean
  @Prop() name!: string
}
