
























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class SwitchComp extends Vue {
  @Prop({ default: false }) presetValue!: boolean
  @Prop() isLanguage!: boolean
  @Prop() languages!: { id: string; label: string }[]

  value = false

  onValue() {
    if (this.isLanguage) {
      this.$emit('onLanguage', this.value ? this.languages[0].id : this.languages[1].id);
    } else {
      this.$emit('input', this.value);
    }
  }

  created() {
    this.value = this.presetValue;

    if (this.isLanguage) {
      const index = this.languages.findIndex((lang) => lang.id === this.$i18n.locale);

      this.value = index !== 0;
    }
  }
}
