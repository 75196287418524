















































import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class';
import LoginModal from './components/LoginModal.vue';
import HeadlineTextBlock from './components/HeadlineTextBlock.vue';
import GenreBlock from './components/GenreBlock.vue';
import ConnectBlock from './components/ConnectBlock.vue';
import ArtistBlock from './components/ArtistBlock.vue';
import FormBlock from './components/FormBlock.vue';
import SubscriptionsBlock from './components/SubscriptionsBlock.vue';
import FooterBlock from './components/FooterBlock.vue';
import Header from './components/Header.vue';
import StickyNotification from './components/StickyNotification.vue';
import ArtistOverlay from './components/ArtistOverlay.vue';
import LoadingSpinner from './components/LoadingSpinner.vue';
import { getGenreFromParam } from './genres';

@Component({
  components: {
    LoadingSpinner,
    LoginModal,
    HeadlineTextBlock,
    GenreBlock,
    ConnectBlock,
    ArtistBlock,
    ArtistOverlay,
    FormBlock,
    SubscriptionsBlock,
    FooterBlock,
    Header,
    StickyNotification,
  },
})
export default class App extends Vue {
  @Mutation('SET_USER_CONTACT_ID') setContactId!: (data: string) => void
  @Mutation('SET_USER_EMAIL_HASH') setEmailHash!: (data: string) => void
  @Mutation('SET_WINDOW_WIDTH') setWidth!: (val: number) => void

  @Action('LOAD_USER_LOGIN_URL') loadLoginUrl!: (data: { service: string; redirectUrl: string }) => Promise<void>
  @Action('LOAD_USER') loadUser!: () => Promise<void>
  @Action('LOAD_USER_WITH_GENRE') loadUserWithGenre!: (genre: string) => Promise<void>
  @Action('LOAD_USER_TOP') loadUserTop!: () => Promise<void>
  @Action('USER_DISCONNECT') userDisconnect!: () => Promise<void>
  @Action('LOAD_GEO_LOCATION') loadGeoLocation!: () => Promise<void>

  @Getter isLoading!: boolean
  @Getter errorMessage!: string
  @Getter userFirstName!: string
  @Getter userEmail!: string
  @Getter userContactId!: string
  @Getter userLoginUrl!: string
  @Getter userChangesCount!: number
  @Getter geoLocation!: string

  isGeoLoaded = false
  isLoginModalOpen = false
  isArtistOverlayOpen = false
  loginService = ''

  isUserError = false

  async created() {
    await this.detectLocation();

    const emailParam = this.getUrlParameter('email');
    const contactId = this.getUrlParameter('contactId');
    const service = this.getUrlParameter('service');
    // Get the preselect genre param (optional)
    const preSelectedGenre = getGenreFromParam(this.getUrlParameter('genre'));

    if (emailParam === null || contactId === null) {
      this.isUserError = true;
      return;
    }

    this.setEmailHash(emailParam);
    this.setContactId(contactId);

    try {
      if (preSelectedGenre) {
        await this.loadUserWithGenre(preSelectedGenre);
      } else {
        await this.loadUser();
      }
    } catch (e) {
      this.isUserError = true;
    }

    if (service !== null) {
      window.history.replaceState(null, '', `${window.location.pathname}?contactId=${contactId}&email=${emailParam}`);
    }
  }

  mounted() {
    this.onResize();
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  }

  onResize() {
    this.setWidth(window.innerWidth);
  }

  async detectLocation() {
    try {
      await this.loadGeoLocation();
    } catch (e) {
      console.log(e);
    }

    if (['de', 'au', 'ch'].includes(this.geoLocation)) {
      this.$i18n.locale = 'de';
    }

    this.isGeoLoaded = true;
  }

  getUrlParameter(key: string): string | null {
    return new URL(window.location.href).searchParams.get(key);
  }

  async onLogin() {
    const redirectUrl = `${window.location.href}&service=spotify`;
    await this.loadLoginUrl({
      service: this.loginService,
      redirectUrl,
    });

    window.location.href = this.userLoginUrl;
  }

  onLoginClose() {
    this.isLoginModalOpen = false;
  }

  onLoginOpen(service: string) {
    this.isLoginModalOpen = true;
    this.loginService = service;
  }

  onDisconnect() {
    this.userDisconnect();
  }
}
