

















































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Pagination extends Vue {
  @Prop() pagesTotal!: number
  @Prop() pageCurrent!: number

  get prevIsBlocked(): boolean {
    return this.pageCurrent === 1;
  }

  get prevColor(): string {
    return this.prevIsBlocked ? '#808080' : '#000';
  }

  get nextIsBlocked(): boolean {
    return this.pageCurrent === this.pagesTotal;
  }

  get nextColor(): string {
    return this.nextIsBlocked ? '#808080' : '#000';
  }

  onPrev() {
    if (this.prevIsBlocked) return;

    this.$emit('onPrev');
  }

  onNext() {
    if (this.nextIsBlocked) return;

    this.$emit('onNext');
  }
}
