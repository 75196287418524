































import { Component, Prop, Vue } from 'vue-property-decorator';
import LoadingSpinner from './LoadingSpinner.vue';

@Component({
  components: { LoadingSpinner },
})
export default class Button extends Vue {
  @Prop() text!: string
  @Prop() isSpotify!: boolean
  @Prop() isSmall!: boolean
  @Prop() isLoading!: boolean
  @Prop() isFullWidth!: boolean
  @Prop() isDark!: boolean
  @Prop() isStatic!: boolean
  @Prop() isSecondary!: boolean

  get classNames() {
    return [
      { 'button--small': this.isSmall },
      { 'button--full-width': this.isFullWidth },
      { 'button--secondary': this.isSecondary },
      { 'button--dark': this.isDark },
      { 'button--static': this.isStatic },
    ];
  }
}
