








































import { Component, Vue } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import Headline from './Headline.vue';
import ArtistTile from './ArtistTile.vue';
import Button from './Button.vue';
import Pagination from './Pagination.vue';
import InputSearch from './InputSearch.vue';
import { Artist } from '../types';

@Component({
  components: {
    Headline,
    ArtistTile,
    Button,
    Pagination,
    InputSearch,
  },
})

export default class ArtistBlock extends Vue {
  @Mutation('SET_USER_ARTISTS') setArtists!: (artist: Artist[]) => void
  @Getter userArtists!: Artist[]
  @Getter isMobile!: boolean

  maxShownDesktop = 15
  maxShownMobile = 7

  currentPage = 1

  get maxShown(): number {
    return this.isMobile ? this.maxShownMobile : this.maxShownDesktop;
  }

  get artists(): Artist[] {
    return this.userArtists.slice(((this.currentPage - 1) * this.maxShown), (this.maxShown * this.currentPage));
  }

  get artistPagesTotal(): number {
    return Math.ceil(this.userArtists.length / this.maxShown);
  }

  onPrev() {
    this.currentPage -= 1;
  }

  onNext() {
    this.currentPage += 1;
  }

  onArtistDelete(artistId: string) {
    this.setArtists([
      ...this.userArtists.filter((a) => a.spotify_id !== artistId),
    ]);
  }
}
