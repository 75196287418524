export const GENRES = [
  {
    translationId: 'pop',
    id: 'Pop',
    color: '#7bc7aa',
    imageUrl: '/images/genres/pop.png',
  },
  {
    translationId: 'hip_hop',
    id: 'HipHop',
    color: '#EA154D',
    imageUrl: '/images/genres/hip_hop.png',
  },
  {
    translationId: 'deutschrap',
    id: 'Deutschrap',
    color: '#F6BD7D',
    imageUrl: '/images/genres/deutschrap.png',
  },
  {
    translationId: 'rnb',
    id: 'R&B',
    color: '#90B3C4',
    imageUrl: '/images/genres/rnb.png',
  },
  {
    translationId: 'dance_edm',
    id: 'Dance/EDM',
    color: '#BD5293',
    imageUrl: '/images/genres/dance_edm.png',
  },
  {
    translationId: 'rock',
    id: 'Rock',
    color: '#F4D576',
    imageUrl: '/images/genres/rock.png',
  },
  {
    translationId: 'schlager',
    id: 'Schlager',
    color: '#EA154D',
    imageUrl: '/images/genres/schlager.png',
  },
  {
    translationId: 'indie_alternative',
    id: 'Indie/Alternative',
    color: '#F6BD7D',
    imageUrl: '/images/genres/indie_alternative.png',
  },
  {
    translationId: 'electronic',
    id: 'Electronic/Progressive',
    color: '#CC433A',
    imageUrl: '/images/genres/electronic.png',
  },
  {
    translationId: 'latin',
    id: 'Latin',
    color: '#248E77',
    imageUrl: '/images/genres/latin.png',
  },
  {
    translationId: 'music_for_kids',
    id: 'Musik für Kids',
    color: '#E4D09F',
    imageUrl: '/images/genres/music_for_kids.png',
  },
  {
    translationId: 'audio_book',
    id: 'Hörbücher und -spiele',
    color: '#E4D09F',
    imageUrl: '/images/genres/audio_book.png',
  },
  {
    translationId: 'jazz',
    id: 'Jazz',
    color: '#428CC5',
    imageUrl: '/images/genres/jazz.png',
  },
  {
    translationId: 'klassik',
    id: 'Klassik',
    color: '#DF6260',
    imageUrl: '/images/genres/klassik.png',
  },
  {
    translationId: 'country',
    id: 'Country',
    color: '#EA154D',
    imageUrl: '/images/genres/country.png',
  },
  {
    translationId: 'metal',
    id: 'Metal',
    color: '#6A7C9F',
    imageUrl: '/images/genres/metal.png',
  },
];

// URL friendly versions of genres to use in pre-select URL param
export const genresByUrlParam: Record<string, string> = {
  'country': 'Country',
  'dance-edm': 'Dance/EDM',
  'deutschrap': 'Deutschrap',
  'electronic-progressive': 'Electronic/Progressive',
  'hiphop': 'HipHop',
  'horbucher-und-spiele': 'Hörbücher und -spiele',
  'indie-alternative': 'Indie/Alternative',
  'jazz': 'Jazz',
  'klassik': 'Klassik',
  'latin': 'Latin',
  'metal': 'Metal',
  'musik-fur-kids': 'Musik für Kids',
  'pop': 'Pop',
  'r-and-b': 'R&B',
  'rock': 'Rock',
  'schlager': 'Schlager',
};

export function getGenreFromParam(param: string|null): string| null{
  // Get the genre from URL param form. E.g. `'dance-edm' -> 'Dance/EDM'`
  // If the param is not in the mapping the same value is returned:
  // E.g. `'Dance/EDM' -> 'Dance/EDM'
  if (param === null) {
    return param;
  }
  return genresByUrlParam[param as string] || param;
}
