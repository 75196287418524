















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class GenreTile extends Vue {
  @Prop() genre!: string
  @Prop() color!: string
  @Prop() image!: string
  @Prop() isActive!: boolean
}
