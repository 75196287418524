















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class InputText extends Vue {
  @Prop() label!: string
  @Prop() value!: string
  @Prop() isDisabled!: boolean
}
