
























import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class';
import Button from './Button.vue';

@Component({
  components: {
    Button,
  },
})

export default class StickyNotification extends Vue {
  @Action('UPDATE_USER') updateUser!: (data: { language: string }) => Promise<void>
  @Mutation('RESET_USER') resetUser!: () => void

  @Prop() isOpen!: boolean

  @Getter userChangesCount!: number

  isLoading = false

  get count(): number {
    return this.userChangesCount < 0 ? -this.userChangesCount : this.userChangesCount;
  }

  onCancel() {
    this.resetUser();
  }

  async onSave() {
    this.isLoading = true;
    await this.updateUser({ language: this.$i18n.locale });
    this.isLoading = false;
  }
}
