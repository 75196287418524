






































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';

import Modal from 'umg-components/src/components/molecules/Modal.vue';
import Card from 'umg-components/src/components/molecules/Card.vue';
import Headline from 'umg-components/src/components/atoms/Headline.vue';
import Paragraph from 'umg-components/src/components/atoms/Paragraph.vue';
import Button from 'umg-components/src/components/atoms/Button.vue';
import Icon from 'umg-components/src/components/atoms/Icon.vue';

@Component({
  components: {
    Modal,
    Card,
    Headline,
    Paragraph,
    Button,
    Icon,
  },
})
export default class LoginModal extends Vue {
  @Prop() isOpen!: boolean

  @Watch('isOpen')
  onToggleOpen() {
    if (this.isOpen) {
      document.body.classList.add('scroll-block');
    } else {
      document.body.classList.remove('scroll-block');
    }
  }
}
