
































































import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import countriesData from '../countries.json';
import countriesDachData from '../countries-dach.json';
import Headline from './Headline.vue';
import InputText from './InputText.vue';
import InputSelect from './InputSelect.vue';
import Button from './Button.vue';

function range(total: number): number[] {
  return [...Array(total).keys()];
}

@Component({
  components: {
    Headline,
    InputText,
    InputSelect,
    Button,
  },
})
export default class FormBlock extends Vue {
  @Action('UPDATE_USER') updateUser!: (data: {
    language: string;
  }) => Promise<void>;

  @Getter errorMessage!: string;
  @Getter userEmail!: string;

  isLoading = false;

  get formFirstName(): string {
    return this.$store.getters.userFirstName;
  }
  set formFirstName(val: string) {
    this.$store.commit('SET_USER_FIRST_NAME', val);
  }

  get formLastName(): string {
    return this.$store.getters.userLastName;
  }
  set formLastName(val: string) {
    this.$store.commit('SET_USER_LAST_NAME', val);
  }

  get formPhone(): string {
    return this.$store.getters.userPhone;
  }
  set formPhone(val: string) {
    this.$store.commit('SET_USER_PHONE', val);
  }

  get formAddressLine1(): string {
    return this.$store.getters.userAddressLine1;
  }
  set formAddressLine1(val: string) {
    this.$store.commit('SET_USER_ADDRESS_LINE_1', val);
  }

  get formAddressLine2(): string {
    return this.$store.getters.userAddressLine2;
  }
  set formAddressLine2(val: string) {
    this.$store.commit('SET_USER_ADDRESS_LINE_2', val);
  }

  get formCity(): string {
    return this.$store.getters.userCity;
  }
  set formCity(val: string) {
    this.$store.commit('SET_USER_CITY', val);
  }

  get formPostcode(): string {
    return this.$store.getters.userPostcode;
  }
  set formPostcode(val: string) {
    this.$store.commit('SET_USER_POSTCODE', val);
  }

  get formCountry(): string {
    return this.$store.getters.userCountry;
  }
  set formCountry(val: string) {
    this.$store.commit('SET_USER_COUNTRY', val);
  }

  get formBirthDay(): string {
    return this.$store.getters.userBirthDay;
  }
  set formBirthDay(val: string) {
    this.$store.commit('SET_USER_BIRTH_DAY', val);
  }

  get formBirthMonth(): string {
    return this.$store.getters.userBirthMonth;
  }
  set formBirthMonth(val: string) {
    this.$store.commit('SET_USER_BIRTH_MONTH', val);
  }

  countriesDach: { label: string; id: string }[] = countriesDachData;
  countries: { label: string; id: string }[] = countriesData;

  get countriesShown(): { label: string; id: string }[] {
    // replace dach country names for german users
    if (this.$i18n.locale === 'de') {
      return [
        ...this.countriesDach,
        ...this.countries.filter(
          (country) => !countriesDachData.find(
            (dachCountry) => dachCountry.id === country.id,
          ),
        ),
      ];
    }

    return this.countries;
  }

  get birthDayRange(): { label: string; id: string }[] {
    return range(31)
      .map((num) => num + 1)
      .map((num) => ({ id: num.toString(), label: num.toString() }));
  }

  get birthMonthRange(): { label: string; id: string }[] {
    return range(12)
      .map((num) => num + 1)
      .map((num) => ({ id: num.toString(), label: num.toString() }));
  }

  async submit() {
    this.isLoading = true;
    try {
      await this.updateUser({ language: this.$i18n.locale });
    } catch (e) {
      alert(e);
    } finally {
      this.isLoading = false;
    }
  }
}
