





















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import Button from './Button.vue';

@Component({
  components: {
    Button,
  },
})
export default class ConnectBlock extends Vue {
  @Prop() connectNumber!: number

  @Getter userSpotifyName!: string
  @Getter userIsSpotifyConnected!: boolean
}
