







































import { Component, Vue } from 'vue-property-decorator';
import { debounce } from 'ts-debounce';
import { Action, Getter, Mutation } from 'vuex-class';
import ArtistTile from './ArtistTile.vue';
import { Artist } from '../types';

@Component({
  components: {
    ArtistTile,
  },
})
export default class Button extends Vue {
  @Action('SEARCH_ARTISTS') searchArtists!: (p: { query: string }) => Promise<void>

  @Mutation('SET_USER_ARTISTS') setArtists!: (artist: Artist[]) => void

  @Getter userArtists!: Artist[];
  @Getter artistsSearch!: Artist[];

  query = ''

  onQuery = debounce(this.load, 500)

  get isOpen(): boolean {
    return this.query.length > 0;
  }

  get queryStripped(): string {
    return this.query.toLowerCase().trim();
  }

  get artistsShown(): Artist[] {
    return this.artistsSearch.filter((as) => !this.userArtists.find((ua) => ua.spotify_id === as.spotify_id));
  }

  reset() {
    this.query = '';
  }

  load() {
    this.searchArtists({ query: this.queryStripped });
  }

  onAddArtist(artist: Artist) {
    this.setArtists([
      artist,
      ...this.userArtists,
    ]);
    this.reset();
  }
}
